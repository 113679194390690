/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FxIdWebFeaturesStoreBank131PayWithRecurrentRequest,
  FxIdWebFeaturesStoreBank131PayWithRecurrentResponse,
  FxIdWebFeaturesStoreBank131PayWithSbpRequest,
  FxIdWebFeaturesStoreBank131PayWithSbpResponse,
  FxIdWebFeaturesStoreBank131PaymentFormDataResponse,
} from '../models';
import {
    FxIdWebFeaturesStoreBank131PayWithRecurrentRequestFromJSON,
    FxIdWebFeaturesStoreBank131PayWithRecurrentRequestToJSON,
    FxIdWebFeaturesStoreBank131PayWithRecurrentResponseFromJSON,
    FxIdWebFeaturesStoreBank131PayWithRecurrentResponseToJSON,
    FxIdWebFeaturesStoreBank131PayWithSbpRequestFromJSON,
    FxIdWebFeaturesStoreBank131PayWithSbpRequestToJSON,
    FxIdWebFeaturesStoreBank131PayWithSbpResponseFromJSON,
    FxIdWebFeaturesStoreBank131PayWithSbpResponseToJSON,
    FxIdWebFeaturesStoreBank131PaymentFormDataResponseFromJSON,
    FxIdWebFeaturesStoreBank131PaymentFormDataResponseToJSON,
} from '../models';

export interface FxIdWebFeaturesStoreBank131PayWithRecurrentEndpointRequest {
    fxIdWebFeaturesStoreBank131PayWithRecurrentRequest: FxIdWebFeaturesStoreBank131PayWithRecurrentRequest;
}

export interface FxIdWebFeaturesStoreBank131PayWithSbpEndpointRequest {
    fxIdWebFeaturesStoreBank131PayWithSbpRequest: FxIdWebFeaturesStoreBank131PayWithSbpRequest;
}

export interface FxIdWebFeaturesStoreBank131PaymentFormDataEndpointRequest {
    publicId: string | null;
}

/**
 * 
 */
export class Bank131Api extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesStoreBank131PayWithRecurrentEndpointRaw(requestParameters: FxIdWebFeaturesStoreBank131PayWithRecurrentEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreBank131PayWithRecurrentResponse>> {
        if (requestParameters.fxIdWebFeaturesStoreBank131PayWithRecurrentRequest === null || requestParameters.fxIdWebFeaturesStoreBank131PayWithRecurrentRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesStoreBank131PayWithRecurrentRequest','Required parameter requestParameters.fxIdWebFeaturesStoreBank131PayWithRecurrentRequest was null or undefined when calling fxIdWebFeaturesStoreBank131PayWithRecurrentEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/bank131/pay_with_recurrent_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesStoreBank131PayWithRecurrentRequestToJSON(requestParameters.fxIdWebFeaturesStoreBank131PayWithRecurrentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreBank131PayWithRecurrentResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreBank131PayWithRecurrentEndpoint(fxIdWebFeaturesStoreBank131PayWithRecurrentRequest: FxIdWebFeaturesStoreBank131PayWithRecurrentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreBank131PayWithRecurrentResponse> {
        const response = await this.fxIdWebFeaturesStoreBank131PayWithRecurrentEndpointRaw({ fxIdWebFeaturesStoreBank131PayWithRecurrentRequest: fxIdWebFeaturesStoreBank131PayWithRecurrentRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreBank131PayWithSbpEndpointRaw(requestParameters: FxIdWebFeaturesStoreBank131PayWithSbpEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreBank131PayWithSbpResponse>> {
        if (requestParameters.fxIdWebFeaturesStoreBank131PayWithSbpRequest === null || requestParameters.fxIdWebFeaturesStoreBank131PayWithSbpRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesStoreBank131PayWithSbpRequest','Required parameter requestParameters.fxIdWebFeaturesStoreBank131PayWithSbpRequest was null or undefined when calling fxIdWebFeaturesStoreBank131PayWithSbpEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/bank131/pay_with_sbp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesStoreBank131PayWithSbpRequestToJSON(requestParameters.fxIdWebFeaturesStoreBank131PayWithSbpRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreBank131PayWithSbpResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreBank131PayWithSbpEndpoint(fxIdWebFeaturesStoreBank131PayWithSbpRequest: FxIdWebFeaturesStoreBank131PayWithSbpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreBank131PayWithSbpResponse> {
        const response = await this.fxIdWebFeaturesStoreBank131PayWithSbpEndpointRaw({ fxIdWebFeaturesStoreBank131PayWithSbpRequest: fxIdWebFeaturesStoreBank131PayWithSbpRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreBank131PaymentFormDataEndpointRaw(requestParameters: FxIdWebFeaturesStoreBank131PaymentFormDataEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreBank131PaymentFormDataResponse>> {
        if (requestParameters.publicId === null || requestParameters.publicId === undefined) {
            throw new runtime.RequiredError('publicId','Required parameter requestParameters.publicId was null or undefined when calling fxIdWebFeaturesStoreBank131PaymentFormDataEndpoint.');
        }

        const queryParameters: any = {};

        if (requestParameters.publicId !== undefined) {
            queryParameters['publicId'] = requestParameters.publicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/bank131/payment_form_data`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreBank131PaymentFormDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreBank131PaymentFormDataEndpoint(publicId: string | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreBank131PaymentFormDataResponse> {
        const response = await this.fxIdWebFeaturesStoreBank131PaymentFormDataEndpointRaw({ publicId: publicId }, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseError } from './FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseError';
import {
    FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseErrorFromJSON,
    FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseErrorFromJSONTyped,
    FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseErrorToJSON,
} from './FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseError';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse
 */
export interface FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse {
    /**
     * 
     * @type {FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseError}
     * @memberof FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse
     */
    Error?: FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseError | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse
     */
    Success: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse
     */
    RedirectUri?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Success" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseFromJSON(json: any): FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse {
    return FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Error': !exists(json, 'Error') ? undefined : FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseErrorFromJSON(json['Error']),
        'Success': json['Success'],
        'RedirectUri': !exists(json, 'RedirectUri') ? undefined : json['RedirectUri'],
    };
}

export function FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseToJSON(value?: FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Error': FxIdWebFeaturesAuthOauthTransferAuthenticateWithExternalIdMyGamesOauthResponseErrorToJSON(value.Error),
        'Success': value.Success,
        'RedirectUri': value.RedirectUri,
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesProfileGetResponseConnectedGame } from './FxIdWebFeaturesProfileGetResponseConnectedGame';
import {
    FxIdWebFeaturesProfileGetResponseConnectedGameFromJSON,
    FxIdWebFeaturesProfileGetResponseConnectedGameFromJSONTyped,
    FxIdWebFeaturesProfileGetResponseConnectedGameToJSON,
} from './FxIdWebFeaturesProfileGetResponseConnectedGame';
import type { FxIdWebFeaturesProfileGetResponseFeature } from './FxIdWebFeaturesProfileGetResponseFeature';
import {
    FxIdWebFeaturesProfileGetResponseFeatureFromJSON,
    FxIdWebFeaturesProfileGetResponseFeatureFromJSONTyped,
    FxIdWebFeaturesProfileGetResponseFeatureToJSON,
} from './FxIdWebFeaturesProfileGetResponseFeature';
import type { FxIdWebFeaturesProfileGetResponseSocialIdentifier } from './FxIdWebFeaturesProfileGetResponseSocialIdentifier';
import {
    FxIdWebFeaturesProfileGetResponseSocialIdentifierFromJSON,
    FxIdWebFeaturesProfileGetResponseSocialIdentifierFromJSONTyped,
    FxIdWebFeaturesProfileGetResponseSocialIdentifierToJSON,
} from './FxIdWebFeaturesProfileGetResponseSocialIdentifier';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesProfileGetResponse
 */
export interface FxIdWebFeaturesProfileGetResponse {
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    AccountId: number;
    /**
     * 
     * @type {Array<FxIdWebFeaturesProfileGetResponseConnectedGame>}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    ConnectedGames: Array<FxIdWebFeaturesProfileGetResponseConnectedGame>;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    MarketingEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    Email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    EmailValidated: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    Locale: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    GeoCountry: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    Name?: string | null;
    /**
     * 
     * @type {Array<FxIdWebFeaturesProfileGetResponseFeature>}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    Features: Array<FxIdWebFeaturesProfileGetResponseFeature>;
    /**
     * 
     * @type {Array<FxIdWebFeaturesProfileGetResponseSocialIdentifier>}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    SocialIdentifiers: Array<FxIdWebFeaturesProfileGetResponseSocialIdentifier>;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    HasEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    Guest: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileGetResponse
     */
    HasCompletedOrder: boolean;
}

/**
 * Check if a given object implements the FxIdWebFeaturesProfileGetResponse interface.
 */
export function instanceOfFxIdWebFeaturesProfileGetResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "AccountId" in value;
    isInstance = isInstance && "ConnectedGames" in value;
    isInstance = isInstance && "MarketingEnabled" in value;
    isInstance = isInstance && "EmailValidated" in value;
    isInstance = isInstance && "Locale" in value;
    isInstance = isInstance && "GeoCountry" in value;
    isInstance = isInstance && "Features" in value;
    isInstance = isInstance && "SocialIdentifiers" in value;
    isInstance = isInstance && "HasEmail" in value;
    isInstance = isInstance && "Guest" in value;
    isInstance = isInstance && "HasCompletedOrder" in value;

    return isInstance;
}

export function FxIdWebFeaturesProfileGetResponseFromJSON(json: any): FxIdWebFeaturesProfileGetResponse {
    return FxIdWebFeaturesProfileGetResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileGetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AccountId': json['AccountId'],
        'ConnectedGames': ((json['ConnectedGames'] as Array<any>).map(FxIdWebFeaturesProfileGetResponseConnectedGameFromJSON)),
        'MarketingEnabled': json['MarketingEnabled'],
        'Email': !exists(json, 'Email') ? undefined : json['Email'],
        'EmailValidated': json['EmailValidated'],
        'Locale': json['Locale'],
        'GeoCountry': json['GeoCountry'],
        'Name': !exists(json, 'Name') ? undefined : json['Name'],
        'Features': ((json['Features'] as Array<any>).map(FxIdWebFeaturesProfileGetResponseFeatureFromJSON)),
        'SocialIdentifiers': ((json['SocialIdentifiers'] as Array<any>).map(FxIdWebFeaturesProfileGetResponseSocialIdentifierFromJSON)),
        'HasEmail': json['HasEmail'],
        'Guest': json['Guest'],
        'HasCompletedOrder': json['HasCompletedOrder'],
    };
}

export function FxIdWebFeaturesProfileGetResponseToJSON(value?: FxIdWebFeaturesProfileGetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AccountId': value.AccountId,
        'ConnectedGames': ((value.ConnectedGames as Array<any>).map(FxIdWebFeaturesProfileGetResponseConnectedGameToJSON)),
        'MarketingEnabled': value.MarketingEnabled,
        'Email': value.Email,
        'EmailValidated': value.EmailValidated,
        'Locale': value.Locale,
        'GeoCountry': value.GeoCountry,
        'Name': value.Name,
        'Features': ((value.Features as Array<any>).map(FxIdWebFeaturesProfileGetResponseFeatureToJSON)),
        'SocialIdentifiers': ((value.SocialIdentifiers as Array<any>).map(FxIdWebFeaturesProfileGetResponseSocialIdentifierToJSON)),
        'HasEmail': value.HasEmail,
        'Guest': value.Guest,
        'HasCompletedOrder': value.HasCompletedOrder,
    };
}


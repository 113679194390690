import {
	FxIdWebFeaturesPlayGamePublicConfigResponse,
	instanceOfFxIdWebFeaturesPlayGamePublicConfigResponse
} from "../../Api/gen";

export const getPublicConfigFromMeta = (
	meta?: Record<string, unknown>
): FxIdWebFeaturesPlayGamePublicConfigResponse | undefined => {
	if (
		typeof meta === "object" &&
		meta !== null &&
		"publicConfig" in meta &&
		typeof meta.publicConfig === "object" &&
		meta.publicConfig !== null &&
		instanceOfFxIdWebFeaturesPlayGamePublicConfigResponse(meta.publicConfig)
	)
		return meta.publicConfig as FxIdWebFeaturesPlayGamePublicConfigResponse;
};

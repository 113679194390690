/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
  FxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest,
  FxIdWebFeaturesFtlIntegrationLauncherActivateKeyResponse,
  FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse,
  FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest,
  FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestResponse,
  FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse,
} from '../models';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
    FxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequestFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequestToJSON,
    FxIdWebFeaturesFtlIntegrationLauncherActivateKeyResponseFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherActivateKeyResponseToJSON,
    FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponseFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponseToJSON,
    FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequestFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequestToJSON,
    FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestResponseFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestResponseToJSON,
    FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseToJSON,
} from '../models';

export interface FxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpointRequest {
    game: string | null;
    fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest: FxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest;
}

export interface FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesEndpointRequest {
    game: string | null;
}

export interface FxIdWebFeaturesFtlIntegrationLauncherDownloadEndpointRequest {
    game: string | null;
    sessionId: string | null;
}

export interface FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpointRequest {
    game: string | null;
    fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest: FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest;
}

export interface FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpointRequest {
    game: string | null;
    branch: string | null;
}

/**
 * 
 */
export class FtlIntegrationApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpointRaw(requestParameters: FxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesFtlIntegrationLauncherActivateKeyResponse>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpoint.');
        }

        if (requestParameters.fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest === null || requestParameters.fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest','Required parameter requestParameters.fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/ftl_integration/{game}/launcher/activate_key`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequestToJSON(requestParameters.fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesFtlIntegrationLauncherActivateKeyResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpoint(game: string | null, fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest: FxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesFtlIntegrationLauncherActivateKeyResponse> {
        const response = await this.fxIdWebFeaturesFtlIntegrationLauncherActivateKeyEndpointRaw({ game: game, fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest: fxIdWebFeaturesFtlIntegrationLauncherActivateKeyRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesEndpointRaw(requestParameters: FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/ftl_integration/{game}/launcher/available_branches`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesEndpoint(game: string | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse> {
        const response = await this.fxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesEndpointRaw({ game: game }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherDownloadEndpointRaw(requestParameters: FxIdWebFeaturesFtlIntegrationLauncherDownloadEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherDownloadEndpoint.');
        }

        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherDownloadEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ftl_integration/{game}/launcher/download/{sessionId}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))).replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherDownloadEndpoint(game: string | null, sessionId: string | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.fxIdWebFeaturesFtlIntegrationLauncherDownloadEndpointRaw({ game: game, sessionId: sessionId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpointRaw(requestParameters: FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestResponse>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpoint.');
        }

        if (requestParameters.fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest === null || requestParameters.fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest','Required parameter requestParameters.fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ftl_integration/{game}/launcher/request_download`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequestToJSON(requestParameters.fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpoint(game: string | null, fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest: FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesFtlIntegrationLauncherDownloadRequestResponse> {
        const response = await this.fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestEndpointRaw({ game: game, fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest: fxIdWebFeaturesFtlIntegrationLauncherDownloadRequestRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpointRaw(requestParameters: FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpoint.');
        }

        if (requestParameters.branch === null || requestParameters.branch === undefined) {
            throw new runtime.RequiredError('branch','Required parameter requestParameters.branch was null or undefined when calling fxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/ftl_integration/{game}/launcher/manifest/{branch}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))).replace(`{${"branch"}}`, encodeURIComponent(String(requestParameters.branch))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpoint(game: string | null, branch: string | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse> {
        const response = await this.fxIdWebFeaturesFtlIntegrationLauncherLauncherManifestEndpointRaw({ game: game, branch: branch }, initOverrides);
        return await response.value();
    }

}

/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsFxEventsClientPublicConfigConfigSection
 */
export interface FxIdDomainSettingsFxEventsClientPublicConfigConfigSection {
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdDomainSettingsFxEventsClientPublicConfigConfigSection
     */
    SendTo: Array<string>;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof FxIdDomainSettingsFxEventsClientPublicConfigConfigSection
     */
    Mapper: { [key: string]: any; };
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof FxIdDomainSettingsFxEventsClientPublicConfigConfigSection
     */
    Matcher: { [key: string]: any; };
}

/**
 * Check if a given object implements the FxIdDomainSettingsFxEventsClientPublicConfigConfigSection interface.
 */
export function instanceOfFxIdDomainSettingsFxEventsClientPublicConfigConfigSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "SendTo" in value;
    isInstance = isInstance && "Mapper" in value;
    isInstance = isInstance && "Matcher" in value;

    return isInstance;
}

export function FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionFromJSON(json: any): FxIdDomainSettingsFxEventsClientPublicConfigConfigSection {
    return FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionFromJSONTyped(json, false);
}

export function FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsFxEventsClientPublicConfigConfigSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'SendTo': json['SendTo'],
        'Mapper': json['Mapper'],
        'Matcher': json['Matcher'],
    };
}

export function FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionToJSON(value?: FxIdDomainSettingsFxEventsClientPublicConfigConfigSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SendTo': value.SendTo,
        'Mapper': value.Mapper,
        'Matcher': value.Matcher,
    };
}


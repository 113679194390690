export interface IVkExternalApiError {
	status: "error";
	errcode: number;
	errmsg: string;
}

export enum EVkExternalApiGetStatus {
	/**
	 *     0 - пользователь не авторизован;
	 */
	NotAuthorized = 0,
	/**
	 *     1 - пользователь авторизован, не зарегистрирован;
	 */
	AuthorizedNotRegistered = 1,
	/**
	 *     2 - пользователь авторизован, зарегистрирован;
	 */
	AuthorizedAndRegistered = 2,
	/**
	 *     3  - пользователь авторизован, зарегистрирован, и совершил покупку игры (для премиум игр).
	 */
	AuthorizedAndRegisteredAndPayed = 3
}

export interface IVkMerchantParams {
	merchant_param: {
		// В merchant_param можно указывать различные параметры, как показано в листинге выше.
		// Весь блок merchant_param вернется в уведомлении при передаче информации о платеже.
		[index: string]: any;

		// Если не указан валюта то merchant_param.amount указывается в рублях.
		amount: number;
		// обязательный параметр, описание получаемой услуги и т.д.
		description: string;
		// валюта, по дефолту 'RUB'
		currency?: "RUB" | "EUR" | "USD";
	};
}

// https://documentation.vkplay.ru/f2p_vkp/f2pb_js_vkp
export interface IVkExternalApi {
	getLoginStatus: () => void;
	userInfo: () => void;
	userProfile: () => void;
	registerUser: () => void;
	authUser: () => void;
	paymentFrame: (param: IVkMerchantParams) => void;
	reloadWindow: () => void;
	showAds: (config: IVkAdsConfig) => void;
}

export interface IVkLoginStatusCallbackResult {
	status: "ok";
	loginStatus: EVkExternalApiGetStatus;
}

export interface IVkUserInfoCallbackResult {
	status: "ok";
	uid: number;
	hash: string;
}

///Где значения Int loginStatus:
//     0 - пользователь не авторизован;
//     1 - пользователь авторизован, не зарегистрирован;
//     2 - пользователь авторизован, зарегистрирован;
//     3  - пользователь авторизован, зарегистрирован, и совершил покупку игры (для премиум игр).
export interface IVkGetStatusCallbackResult {
	status: "ok";
	loginStatus: number;
}

export const VkLoginStatusUserNotAuthorized = 0;
export const VkLoginStatusUserAuthorizedNotRegistered = 1;
export const VkLoginStatusUserAuthorizedAndRegistered = 2;
export const VkLoginStatusUserAuthorizedAndRegisteredAndPayed = 3;

export interface IVkIframeApiOptions {
	debug?: boolean;
}

export interface IVkAdsConfig {
	sources?: "admanSource" | "admanagerSource" | ("admanSource" | "admanagerSource")[];
	interstitial: boolean;
}

export interface IVkAdsCallbackResult {
	type: "adCompleted" | "adDismissed" | "adError";
	code?: "UndefinedAdError" | "AdblockDetectedAdError" | "WaterfallConfigLoadFailed";
}

// https://documentation.vkplay.ru/f2p_vkp/f2pb_js_vkp
export interface IVkIframeApiCallbacks {
	appid: number;
	getLoginStatusCallback?: (param: IVkLoginStatusCallbackResult | IVkExternalApiError) => void;
	userInfoCallback?: (info: IVkUserInfoCallbackResult | IVkExternalApiError) => void;
	userProfileCallback?: (
		profile:
			| {
					status: string;
					uid: number;
					nick: string;
					avatar: string;
					birthyear: string;
					sex: string;
					slug: string;
			  }
			| IVkExternalApiError
	) => void;
	registerUserCallback?: (info: IVkUserInfoCallbackResult | IVkExternalApiError) => void;
	paymentFrameUrlCallback?: (url: string) => void;
	getAuthTokenCallback?: (token: string) => void;
	paymentReceivedCallback?: (data: { uid: string }) => void;
	paymentWindowClosedCallback?: () => void;
	userConfirmCallback?: () => void;
	adsCallback: (context: IVkAdsCallbackResult) => void;
}

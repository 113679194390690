/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLogin } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLogin';
import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGames } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGames';
import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGames';
import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLogin } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLogin';
import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFxIdLogin } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFxIdLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFxIdLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFxIdLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFxIdLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFxIdLogin';
import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregate } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregate';
import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregate';
import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLogin } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLogin';
import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoSteamLogin } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoSteamLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoSteamLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoSteamLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoSteamLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoSteamLogin';
import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoTelegram } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoTelegram';
import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoTelegramFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoTelegramFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoTelegramToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoTelegram';
import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLogin } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLogin';
import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLogin } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLogin';
import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQ } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQ';
import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQ';
import type { FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLogin } from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLogin';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromSocialRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateFromSocialRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    Social: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    Locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    SessionId?: string | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoSteamLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    SteamLogin?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoSteamLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    FacebookGamesLogin?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    VkontaktePlayLogin?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    VkontakteComLogin?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    OdnoklassnikiLogin?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    YandexGamesLogin?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    AbsoluteGamesLogin?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFxIdLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    FxIdLogin?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFxIdLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGames}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    CrazyGames?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGames | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQ}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    WizQ?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQ | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoTelegram}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    Telegram?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoTelegram | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregate}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialRequest
     */
    Kongregate?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregate | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromSocialRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromSocialRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Social" in value;
    isInstance = isInstance && "Game" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromSocialRequest {
    return FxIdWebFeaturesAuthAuthenticateFromSocialRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromSocialRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Social': json['Social'],
        'Game': json['Game'],
        'Locale': !exists(json, 'Locale') ? undefined : json['Locale'],
        'SessionId': !exists(json, 'SessionId') ? undefined : json['SessionId'],
        'SteamLogin': !exists(json, 'SteamLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoSteamLoginFromJSON(json['SteamLogin']),
        'FacebookGamesLogin': !exists(json, 'FacebookGamesLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginFromJSON(json['FacebookGamesLogin']),
        'VkontaktePlayLogin': !exists(json, 'VkontaktePlayLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginFromJSON(json['VkontaktePlayLogin']),
        'VkontakteComLogin': !exists(json, 'VkontakteComLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginFromJSON(json['VkontakteComLogin']),
        'OdnoklassnikiLogin': !exists(json, 'OdnoklassnikiLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginFromJSON(json['OdnoklassnikiLogin']),
        'YandexGamesLogin': !exists(json, 'YandexGamesLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginFromJSON(json['YandexGamesLogin']),
        'AbsoluteGamesLogin': !exists(json, 'AbsoluteGamesLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginFromJSON(json['AbsoluteGamesLogin']),
        'FxIdLogin': !exists(json, 'FxIdLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFxIdLoginFromJSON(json['FxIdLogin']),
        'CrazyGames': !exists(json, 'CrazyGames') ? undefined : FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesFromJSON(json['CrazyGames']),
        'WizQ': !exists(json, 'WizQ') ? undefined : FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQFromJSON(json['WizQ']),
        'Telegram': !exists(json, 'Telegram') ? undefined : FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoTelegramFromJSON(json['Telegram']),
        'Kongregate': !exists(json, 'Kongregate') ? undefined : FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateFromJSON(json['Kongregate']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialRequestToJSON(value?: FxIdWebFeaturesAuthAuthenticateFromSocialRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Social': value.Social,
        'Game': value.Game,
        'Locale': value.Locale,
        'SessionId': value.SessionId,
        'SteamLogin': FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoSteamLoginToJSON(value.SteamLogin),
        'FacebookGamesLogin': FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginToJSON(value.FacebookGamesLogin),
        'VkontaktePlayLogin': FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginToJSON(value.VkontaktePlayLogin),
        'VkontakteComLogin': FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginToJSON(value.VkontakteComLogin),
        'OdnoklassnikiLogin': FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoOdnoklassnikiLoginToJSON(value.OdnoklassnikiLogin),
        'YandexGamesLogin': FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginToJSON(value.YandexGamesLogin),
        'AbsoluteGamesLogin': FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginToJSON(value.AbsoluteGamesLogin),
        'FxIdLogin': FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFxIdLoginToJSON(value.FxIdLogin),
        'CrazyGames': FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoCrazyGamesToJSON(value.CrazyGames),
        'WizQ': FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoWizQToJSON(value.WizQ),
        'Telegram': FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoTelegramToJSON(value.Telegram),
        'Kongregate': FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateToJSON(value.Kongregate),
    };
}


/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
} from '../models';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
} from '../models';

export interface GetFxIdWebFeaturesRedirectGoEndpointRequest {
    url: string | null;
    fxref?: number | null;
    type?: string | null;
}

/**
 * 
 */
export class GoApi extends runtime.BaseAPI {

    /**
     */
    async getFxIdWebFeaturesRedirectGoEndpointRaw(requestParameters: GetFxIdWebFeaturesRedirectGoEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.url === null || requestParameters.url === undefined) {
            throw new runtime.RequiredError('url','Required parameter requestParameters.url was null or undefined when calling getFxIdWebFeaturesRedirectGoEndpoint.');
        }

        const queryParameters: any = {};

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        if (requestParameters.fxref !== undefined) {
            queryParameters['fxref'] = requestParameters.fxref;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/go`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getFxIdWebFeaturesRedirectGoEndpoint(url: string | null, fxref?: number | null, type?: string | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.getFxIdWebFeaturesRedirectGoEndpointRaw({ url: url, fxref: fxref, type: type }, initOverrides);
        return await response.value();
    }

    /**
     */
    async postFxIdWebFeaturesRedirectGoEndpointRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/go`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async postFxIdWebFeaturesRedirectGoEndpoint(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.postFxIdWebFeaturesRedirectGoEndpointRaw(initOverrides);
        return await response.value();
    }

}
